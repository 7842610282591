import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getUserLifeline, getPhaseEntries, createPhaseEntry, getPhaseIdByEntryId, getEntryDetails, updatePhaseEntry, deletePhaseEntry, getPresignedUrl, uploadFileToS3 } from '../api/ninox';
import SVGIcon from '../components/SVGIcon';
import { motion, AnimatePresence } from "framer-motion";
import ZodiacIcon from 'components/ZodiacIcon';
import { Camera, Plus , X } from "@phosphor-icons/react";
import { v4 as uuidv4 } from 'uuid';


// Funktion zum Deaktivieren des Scrollens
const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  
  // Funktion zum Aktivieren des Scrollens
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const generateUniqueFileName = (originalFileName) => {
    const timestamp = Date.now(); // Aktueller Zeitstempel
    const uniqueId = uuidv4(); // Eindeutige UUID
    const fileExtension = originalFileName.split('.').pop(); // Dateiendung extrahieren
    return `${uniqueId}-${timestamp}.${fileExtension}`; // Neuer Dateiname
  };
  
const Timeline = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState({ firstName: '', lastName: '', zodiac:'', zodiacName:'', birthDay:'' });
  const [phases, setPhases] = useState([]);
  const [expandedPhases, setExpandedPhases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [creatingEntry, setCreatingEntry] = useState(false);
  const [error, setError] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newEntryData, setNewEntryData] = useState({ year: '', month: '', day: '', title: '', description: '', photos: [],  audioBlob: null });
  const [isSaving, setIsSaving] = useState(false); // Zustand für "Speichern"
  const [entryDetails, setEntryDetails] = useState(null); // Für die Detailansicht eines Eintrags
  const [userId, setUserId] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(localStorage.getItem('userName') || '');
  const [loginInput, setLoginInput] = useState('');
  const [showLogin, setShowLogin] = useState(false);  
  const [editingEntry, setEditingEntry] = useState(null); // Für den Bearbeitungsmodus
  const entryRefs = useRef({}); // For scrolling references
  const [showLoginMessage, setShowLoginMessage] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const entryId = searchParams.get('entryId');

  const months = [
    { name: "Januar", value: 1 },
    { name: "Februar", value: 2 },
    { name: "März", value: 3 },
    { name: "April", value: 4 },
    { name: "Mai", value: 5 },
    { name: "Juni", value: 6 },
    { name: "Juli", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "Oktober", value: 10 },
    { name: "November", value: 11 },
    { name: "Dezember", value: 12 }
  ];
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (e) => {
        setNewEntryData((prevData) => ({
          ...prevData,
          audioBlob: e.data, // Aufnahme in newEntryData speichern
        }));
      };
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    } catch (err) {
      console.error('Fehler bei der Aufnahme:', err);
      alert('Audioaufnahme nicht möglich.');
    }
  };
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };   
  useEffect(() => {
    if (!showLogin && loggedInUser) {
     localStorage.setItem('userName', loggedInUser);    }
  }, [loggedInUser, showLogin]);

  const handleLogin = () => {
    if (loginInput.trim()) {
      setLoggedInUser(loginInput.trim());
      setShowLogin(false);
    } else {
      alert('Please enter your name!');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('userName');
    setLoggedInUser('');
    setShowLogin(true);
  };

  const handleCreateEntryClick = () => {
    if (loggedInUser) {
      setShowCreateForm(true);
    } else {
      setShowLogin(true);
    }
  };


  useEffect(() => {
    const shouldDisableScroll = entryDetails || showCreateForm || editingEntry;
    
    if (shouldDisableScroll) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [entryDetails, showCreateForm, editingEntry]);
  
  useEffect(() => {
    const fetchLifeline = async () => {
      setLoading(true);
      try {
        const idFromSlug = slug.split('-')[0];
        setUserId(idFromSlug);
        const response = await getUserLifeline(idFromSlug);
        setPhases(response.result.phases || []);

            setUserName({
              firstName: response.result.user.firstName,
              lastName: response.result.user.lastName,
              zodiac: response.result.user.zodiac,
              zodiacName: response.result.user.zodiacName,
              birthDay: formatDate(response.result.user.birthDay)
            });
      } catch (err) {
        console.error('Error loading lifeline:', err.message);
        setError('Error loading lifeline.');
      } finally {
        setLoading(false);
      }
    };

    fetchLifeline();
  }, [slug]);

  useEffect(() => {
    const scrollToEntry = async () => {
      if (!entryId) return;
  
      try {
        const response = await getPhaseIdByEntryId(entryId);
        const phaseId = response.result?.phaseId;
  
        if (!phaseId) {
          console.warn('No phase found for entry ID:', entryId);
          return;
        }
  
        console.log(phaseId);
  
        // Scroll to the entry
        setTimeout(() => {
          const targetElement = entryRefs.current[entryId];
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            console.warn('No HTML element found for entry ID:', entryId);
          }
        }, 300);
      } catch (err) {
        console.error('Error finding phase for entry ID:', err.message);
      }
    };
  
    const loadEntryDetails = async () => {
      if (!entryId) return;
  
      try {
        const response = await getEntryDetails(entryId); // API-Aufruf, um Eintragsdetails zu laden
        console.log('API response:', response); // Log the entire API response
  
        if (response) {
          console.log('Entry details loaded:', response); // Log the loaded entry details
          setEntryDetails(response); // Detaildaten im State speichern
        } else {
          console.warn('No entry details found in response:', response);
        }
      } catch (err) {
        console.error('Error loading entry details:', err.message);
      }
    };
  
    scrollToEntry();
    loadEntryDetails();
  }, [entryId, phases]);

  const togglePhase = (phaseId) => {
    if (!expandedPhases.includes(phaseId)) {
      // Phase öffnen
      setExpandedPhases((prev) => [...prev, phaseId]);
  
      // Daten laden
      loadPhaseEntries(phaseId);
    } else {
      // Phase schließen
      setExpandedPhases((prev) => prev.filter((id) => id !== phaseId));
    }
  };
  
  const loadPhaseEntries = async (phaseId) => {
    if (phases.find((phase) => phase.id === phaseId)?.entries?.length > 0) return;
  
    setLoadingEntries(true);
    try {
      const response = await getPhaseEntries(phaseId);
  
      setPhases((prevPhases) =>
        prevPhases.map((phase) =>
          phase.id === phaseId ? { ...phase, entries: response.result.entries || [] } : phase
        )
      );
    } catch (err) {
      console.error('Error loading entries:', err);
      setError('Fehler beim Laden der Einträge.');
    } finally {
      setLoadingEntries(false);
    }
  };
  
  
  

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCreateEntry = async () => {
    const { year, month, day, title, description, photos, audioBlob } = newEntryData;
  
    if (!year || !month) {
      alert('Year and month are required!');
      return;
    }
  
    setIsSaving(true); // Ladezustand starten
  
    try {
      // Fotos hochladen
      const uploadedPhotos = [];
      for (const photo of photos) {
        const presignedUrl = await getPresignedUrl(photo.name, photo.type);
        await uploadFileToS3(presignedUrl, photo);
        uploadedPhotos.push({ url: presignedUrl.split('?')[0], name: photo.name });
      }
  
      // Sprachaufnahme hochladen
      let audioUrl = null;
      if (audioBlob) {
        const audioFile = new File([audioBlob], 'recording.webm', { type: 'audio/webm' });
        const presignedUrl = await getPresignedUrl(audioFile.name, audioFile.type);
        await uploadFileToS3(presignedUrl, audioFile);
        audioUrl = presignedUrl.split('?')[0];
      }
  
      // Payload erstellen
      const payload = {
        userId,
        userName: loggedInUser,
        year: parseInt(year),
        month: parseInt(month),
        day: day ? parseInt(day) : null,
        title,
        description,
        photos: uploadedPhotos,
        audio: audioUrl, // Sprachaufnahme-URL
      };
  
      console.log('Speichere Eintrag:', payload);
      const response = await createPhaseEntry(payload);
      console.log('Eintrag gespeichert:', response);
  
      // Formular zurücksetzen
      setNewEntryData({ year: '', month: '', day: '', title: '', description: '', photos: [], audioBlob: null });
      setShowCreateForm(false);
      await togglePhase(response.result.phaseId);
    } catch (err) {
      console.error('Fehler beim Speichern des Eintrags:', err.message);
      alert('Fehler beim Speichern des Eintrags.');
    } finally {
      setIsSaving(false); // Ladezustand beenden
    }
  };
  
  
  
  

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files).map((file) => {
      const uniqueName = generateUniqueFileName(file.name); // Eindeutigen Namen generieren
      const renamedFile = new File([file], uniqueName, { type: file.type }); // Datei mit neuem Namen erstellen
      return renamedFile;
    });
  
    setNewEntryData((prevData) => ({
      ...prevData,
      photos: [...prevData.photos, ...files],
    }));
  };

  const handleEntryClick = async (id) => {
    console.log(`Entry clicked: ${id}`); // Log the clicked entry ID
    navigate(`/timeline/${slug}?entryId=${id}`, { replace: true });
  
    try {
      const response = await getEntryDetails(id); // API-Aufruf, um Eintragsdetails zu laden
      console.log('API response:', response); // Log the entire API response
  
      if (response) {
        console.log('Entry details loaded:', response); // Log the loaded entry details
        setEntryDetails(response); // Detaildaten im State speichern
      } else {
        console.warn('No entry details found in response:', response);
      }
    } catch (err) {
      console.error('Error loading entry details:', err.message);
    }
  };

  const closePopover = () => {
    const params = new URLSearchParams(location.search);
    params.delete('entryId'); // Entfernt entryId aus der URL
    navigate(`/timeline/${slug}?${params.toString()}`, { replace: true });
    setEntryDetails(null); // Schließt das Popover
  };
  const handleSaveEntry = async (updatedEntry) => {
    try {
      const payload = {
        ...updatedEntry,
        userId, // Benutzer-ID hinzufügen
        entryId: updatedEntry.data?.entryId || updatedEntry.entryId, // Sicherstellen, dass entryId enthalten ist
    };
  
      console.log('Updating entry with payload:', payload);
  
      // API-Aufruf, um den Eintrag zu aktualisieren
      const response = await updatePhaseEntry(payload); // Backend-Endpunkt muss angepasst sein
      console.log('Update response:', response);
  
      // Phase und Eintrag aktualisieren
      await togglePhase(updatedEntry.phaseId);
  
      setEditingEntry(null); // Bearbeitungsmodus verlassen
      setEntryDetails(null); // Detailansicht schließen
    } catch (error) {
      console.error('Fehler beim Speichern des Eintrags:', error.message);
    }
  };

  const handleDeleteEntry = async (entryId, phaseId) => {
    if (!window.confirm('Möchtest du diesen Eintrag wirklich löschen?')) return;
  
    try {
    // Zuerst URL bereinigen und Navigation durchführen
    const params = new URLSearchParams(location.search);
    params.delete('entryId');
    navigate(`/timeline/${slug}?${params.toString()}`, { replace: true });

      console.log('Lösche Eintrag:', entryId);
      const payload = { entryId: entryId }; // Korrektes Payload-Format
      const deleteResponse = await deletePhaseEntry(payload);

  
      // Phase und Einträge aktualisieren
      setPhases((prevPhases) =>
        prevPhases.map((phase) =>
          phase.id === phaseId
            ? {
                ...phase,
                entries: phase.entries.filter((entry) => entry.id !== entryId),
                eventCount: deleteResponse.result.eventCount // Neuer eventCount aus Response
              }
            : phase
        )
      );
  
      setEditingEntry(null);
      setEntryDetails(null); 

      console.log('Eintrag erfolgreich gelöscht:', editingEntry);
      console.log('Eintrag erfolgreich gelöscht:', entryId);
    } catch (error) {
      console.error('Fehler beim Löschen des Eintrags:', error.message);
      alert('Der Eintrag konnte nicht gelöscht werden.');
    }
  };
  
  return (
    <div className="relative flex flex-col items-center min-h-screen bg-gray-100">
      {/* Timeline Header */}
      <div className="flex flex-col items-center w-full bg-sky-100">
      <nav className="flex items-center justify-between w-full px-6 py-4 bg-blue-800 shadow-md">
      {/* Logo links */}
      <div className="flex items-center">
<svg className="w-[100px]" id="Ebene_1" data-name="Ebene 1" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.86 28.57">
  <g>
    <rect class="cls-1" x="35.41" y=".09" width="5.32" height="22.16"/>
    <path class="cls-1" d="M46.47,0c-.81,0-1.48.27-2.01.82-.53.55-.79,1.21-.79,2.01s.26,1.46.79,2.02c.53.56,1.2.84,2.01.84s1.45-.28,1.98-.84c.53-.56.79-1.23.79-2.02s-.26-1.46-.79-2.01c-.53-.55-1.19-.82-1.98-.82Z"/>
    <rect class="cls-1" x="43.76" y="7.39" width="5.32" height="14.86"/>
    <polygon class="cls-1" points="59.01 16.34 56.25 7.39 50.57 7.39 56.65 22.25 61.33 22.25 67.38 7.39 61.75 7.39 59.01 16.34"/>
    <path class="cls-1" d="M71.54,0c-.81,0-1.48.27-2.01.82-.53.55-.79,1.21-.79,2.01s.26,1.46.79,2.02c.53.56,1.2.84,2.01.84s1.45-.28,1.98-.84c.53-.56.79-1.23.79-2.02s-.26-1.46-.79-2.01c-.53-.55-1.19-.82-1.98-.82Z"/>
    <rect class="cls-1" x="68.84" y="7.39" width="5.32" height="14.86"/>
    <path class="cls-1" d="M111.13,9.82c-.49-.83-1.15-1.49-1.99-1.99-.84-.5-1.8-.75-2.87-.75-1.16,0-2.2.23-3.13.7-.33.17-.63.37-.91.59v-.98h-5.32v14.86h5.32v-8.39c0-.45.09-.83.27-1.17.18-.33.44-.59.77-.76.33-.17.71-.26,1.14-.26.63,0,1.14.19,1.53.58s.59.91.59,1.58v8.42h5.32v-9.63c0-1.03-.24-1.96-.73-2.8Z"/>
    <path class="cls-1" d="M90.06,7.78c-.13.04-.22.13-.28.24-.08-.05-.15-.1-.23-.14-1.25-.68-2.68-1.02-4.3-1.02s-2.97.34-4.22,1.02c-1.26.68-2.24,1.6-2.96,2.77-.72,1.17-1.08,2.5-1.08,4,0,.38.04.74.09,1.09-2.22,2.35-3.02,4.33-2.25,5.6.46.75,1.38,1.13,2.71,1.13.95,0,2.11-.2,3.44-.59h0s.02,0,.03,0c.18-.06.38-.13.56-.19,1.12.51,2.36.78,3.72.78,1.6,0,3.03-.34,4.29-1.03s2.24-1.63,2.95-2.81c.71-1.19,1.06-2.51,1.06-3.97,0-.11-.01-.22-.02-.33.1-.1.21-.19.3-.29,2.3-2.4,3.13-4.42,2.34-5.71-.78-1.29-2.97-1.48-6.15-.54ZM82.74,13.08c.25-.46.6-.82,1.03-1.08s.93-.4,1.47-.4c.59,0,1.1.13,1.53.38s.78.61,1.03,1.06c.25.46.38.99.38,1.6,0,.16-.02.3-.04.44-.57.4-1.16.79-1.78,1.17-.41.25-.82.49-1.23.71-.33.18-.65.36-.98.53-.13-.05-.27-.1-.39-.17-.44-.26-.78-.62-1.03-1.08-.25-.46-.38-.98-.38-1.56s.13-1.14.38-1.6ZM77.96,18.42c.03.06.06.13.1.2.18.29.38.56.59.81-.6.04-.87-.07-.93-.17-.05-.09-.04-.36.24-.84ZM93.13,13.34c-1.48,1.54-3.54,3.14-5.79,4.51-2.26,1.38-4.56,2.44-6.65,3.07-.01,0-.02,0-.03,0-2.57.76-4.48.72-4.98-.11-.45-.73.16-2.15,1.59-3.82l.12.46c-.69.97-.86,1.74-.52,2.31.36.6,1.18.79,2.49.58.65-.11,1.39-.31,2.22-.62.11-.04.22-.08.31-.12,1.15-.44,2.44-1.05,3.73-1.77.42-.23.84-.48,1.27-.74,1.81-1.1,3.51-2.36,4.79-3.54.64-.59,1.16-1.15,1.53-1.65.04-.03.07-.07.09-.12.73-1,.92-1.8.56-2.38-.35-.57-1.12-.77-2.29-.6l-.36-.32c2.13-.51,3.68-.4,4.12.33.5.83-.34,2.55-2.21,4.5ZM91.88,9.79c.76-.09,1.08.05,1.14.15.06.1.02.44-.36,1.03-.06-.1-.1-.21-.16-.32-.19-.3-.4-.59-.62-.86Z"/>
    <path class="cls-1" d="M89.28,6.58c.43,0,.77-.35.77-.77s-.35-.77-.77-.77-.77.35-.77.77.35.77.77.77Z"/>
    <path class="cls-1" d="M83.18,24.4c-.7,0-1.27.57-1.27,1.27s.57,1.27,1.27,1.27,1.27-.57,1.27-1.27-.57-1.27-1.27-1.27Z"/>
    <circle class="cls-1" cx="79.74" cy="24.38" r=".63"/>
  </g>
  <g>
    <path class="cls-2" d="M7.23,22.58c-1.42,0-2.67-.28-3.75-.84-1.08-.56-1.94-1.34-2.55-2.34-.62-1-.93-2.14-.93-3.42V7.39h5.32v8.6c0,.41.08.76.23,1.06.15.3.37.54.65.7.28.16.62.24,1,.24.57,0,1.02-.18,1.37-.53.34-.35.52-.85.52-1.47V7.39h5.32v8.6c0,1.3-.3,2.44-.91,3.43-.61.99-1.45,1.77-2.54,2.32-1.08.56-2.32.84-3.72.84Z"/>
    <path class="cls-2" d="M32.34,10.82c-.61-1.17-1.46-2.09-2.57-2.75-1.1-.66-2.38-.99-3.81-.99-.95,0-1.83.19-2.63.56-.34.16-.64.35-.93.56v-.82h-5.32v21.18h5.2v-7.12c.32.22.68.42,1.06.58.81.34,1.68.52,2.61.52,1.44,0,2.7-.33,3.8-1s1.95-1.58,2.57-2.75.93-2.5.93-4-.3-2.81-.91-3.98ZM27.52,16.37c-.23.46-.57.82-1.02,1.08-.45.26-.96.39-1.55.39-.55,0-1.04-.13-1.47-.39-.44-.26-.77-.62-1-1.08-.23-.46-.35-.98-.35-1.57s.12-1.13.36-1.58.58-.79,1.02-1.05c.44-.25.93-.38,1.47-.38.59,0,1.1.13,1.53.4.44.26.77.62,1,1.06.23.45.35.96.35,1.55s-.12,1.11-.35,1.57Z"/>
  </g>
</svg>
      </div>

      {/* Benutzeranzeige und Logout rechts */}
      <div className="flex items-center">
        {loggedInUser ? (
          <>
            <div className="flex items-center justify-center w-auto h-6 px-2 text-sm font-bold text-blue-900 bg-white border rounded-full whitespace-nowrap">
              {loggedInUser}
            </div>
            <button
              className="ml-4 text-xs text-white"
              onClick={handleLogout}
            >
              Logout
            </button>
          </>
        ) : (
          <button
            className="text-sm text-white"
            onClick={() => setShowLogin(true)}
          >
            Login
          </button>
        )}
      </div>
    </nav>

        <div className="flex flex-col items-center w-full py-6">
              <ZodiacIcon name={userName.zodiac} color="#1e3a8a" size={264}/>
              <div
                className="flex flex-col items-center mt-10 text-center"
              >
                 
                  <h2 className="font-bold text-blue-900/[.46] text-1xl">Zeitlinie</h2>
                  <p className="text-sm text-blue-900/[.46]">von</p>
                  <p className="text-4xl text-blue-900">{userName.firstName} {userName.lastName}</p>
                  <p className="text-2xl text-blue-900">{userName.birthDay}</p>
                  <p className="text-2xl text-blue-900">{userName.zodiacName}</p>

              </div>
        </div>
        {phases.map((phase, index) => (
          <div key={phase.id} className="flex flex-col w-full phase-container">
            
            <div className="relative flex w-full py-10 min-h-[350px] items-end">
            <div className="phase-line"></div>
              {index % 2 !== 0 && <div className="flex flex-col items-end w-1/2 pt-8 pr-8">
                <div className='w-8 h-2 mb-6 bg-orange-400'></div>
                <h2 className="text-4xl font-black text-blue-900">{phase.yearFrom}</h2>
                <h3 className="text-2xl font-black text-blue-900">bis {phase.yearTo}</h3>
                <button
  type="button"
  className="inline-flex items-center w-auto px-2 py-1 mt-4 text-xs font-bold text-white bg-orange-400 rounded-full ring-1 ring-inset ring-yellow-600/20"
  onClick={() => togglePhase(phase.id)}
>
  {expandedPhases.includes(phase.id)
    ? 'Ereignisse schließen'
    : `${phase.eventCount} Ereignisse anzeigen`}
</button>

              </div>}
              <div
                className={`w-1/2 ${
                  index % 2 === 0 ? 'pr-8 pl-8 items-end text-right' : 'pl-8 pr-8 items-start text-left'
                } flex flex-col`}
              >
                  <div className="mb-3"><SVGIcon name={phase.icon} color="#1e3a8a" size={64} /></div>
                  <h2 className="font-bold text-blue-900 text-1xl">{phase.name}</h2>
                  <p className="text-sm text-blue-900/[.46]">{phase.description}</p>
                  <p className="text-sm text-blue-900/[.46]">Kosmische Aspekte: {phase.cosmicAspects}</p>

              </div>
              {index % 2 === 0 && 
              <div className="flex flex-col items-start w-1/2 pt-8 pl-8">
                <div className='w-8 h-2 mb-6 bg-orange-400'></div>
                <h2 className="text-4xl font-black text-blue-900">{phase.yearFrom}</h2>
                <h3 className="text-2xl font-black text-blue-900">bis {phase.yearTo}</h3>
                <button
  type="button"
  className={`inline-flex items-center w-auto px-2 py-1 mt-4 text-xs font-bold text-white rounded-full ring-1 ring-inset ${
    phase.eventCount < 1 
    ? 'bg-orange-400/50 ring-yellow-600/10' 
    : 'bg-orange-400 ring-yellow-600/20'
  }`}  onClick={() => togglePhase(phase.id)}
>
  {expandedPhases.includes(phase.id)
    ? 'Ereignisse schließen'
    : `${phase.eventCount} Ereignisse anzeigen`}
</button>
              </div>}
            </div>
            <AnimatePresence mode="sync">
  {expandedPhases.includes(phase.id) && (
    <motion.div
      className="relative w-full bg-sky-50 entries-container"
      initial={{ height: 0 }}
      animate={{ height: "auto" }}
      exit={{ height: 0, transition: { ease: 'easeIn', delay:0.4 }  }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
    >
      {/* Linie (falls erforderlich) */}
      <div className="entries-line"></div>

      {/* Container für Inhalte */}
      <motion.div
        className="flex flex-col w-full max-w-4xl py-8 mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { ease: 'easeIn', delay:0 }  }}
        transition={{ duration: 0.3, delay: 0.3 }} // Leichter Delay für den Fade-In
      >
        {loadingEntries ? (
          <div className="flex items-center justify-center h-[300px]">
            <p className="text-gray-500">Loading entries...</p>
          </div>
        ) : phase.entries && phase.entries.length > 0 ? (
          <div className="flex flex-col w-full">
            {phase.entries.map((entry, entryIndex) => (
              <div
                key={entry.id}
                ref={(el) => (entryRefs.current[entry.id] = el)}
                onClick={() => handleEntryClick(entry.id)}
                className="flex w-full mb-6 cursor-pointer"
              >
                {/* Links / Rechts Styling basierend auf Index */}
                {entryIndex % 2 !== 0 && <div className="w-1/2"></div>}
                <div
                  className={`w-1/2 ${
                    entryIndex % 2 === 0
                      ? "pr-6 items-end text-right"
                      : "pl-6 items-start text-left"
                  } flex flex-col`}
                >
{/* Rendering der Bilder */}
<div className="relative flex flex-row items-start mb-4 w-max">
  {entry.images?.slice(0, 3).map((image, imgIndex) => (
    <div
      className="relative w-12 h-12 overflow-hidden"
      key={imgIndex}
      style={{ marginRight: '0.25rem' }}
    >
      <img
        src={image.url}
        alt={`Entry image ${imgIndex + 1}`}
        className="object-cover w-12 h-12 rounded-full"
      />
    </div>
  ))}
  {/* Badge für zusätzliche Bilder */}
  {entry.images?.length > 3 && (
    <div
      className={`absolute -top-3 p-3 font-semibold border-2 border-white flex items-center justify-center w-5 h-5 text-xs text-white bg-blue-500 rounded-full ${
        entryIndex % 2 === 0 ? '-right-2' : '-left-2'
      }`}
    >
      {entry.images.length}
    </div>
  )}
</div>

                  <h3 className="text-lg font-bold leading-tight text-orange-400">
                    {entry.dateTitle}
                  </h3>
                  <p className="text-sm font-semibold leading-tight text-blue-900">
                    {entry.title}
                  </p>
                  <p className="mt-2 text-xs text-blue-300">{entry.creator}</p>
                </div>
                {entryIndex % 2 === 0 && <div className="w-1/2"></div>}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="px-3 py-1 text-sm text-center text-blue-900/[.46] rounded-full bg-sky-50">
              <b>Keine Einträge gefunden.</b>
              <br />
              Poste die erste Erinnerung in {userName.firstName}s {phase.name}
            </p>
          </div>
        )}
      </motion.div>
    </motion.div>
  )}
</AnimatePresence>

          </div>
        ))}
      </div>

      {/* Floating Action Button */}
      {/* Login Form */}
      {showLogin && (
        <AnimatePresence mode="sync">
          <motion.div 
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ duration: 0.3 }}  
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-6 bg-white rounded shadow-lg">
            <h3 className="mb-4 text-xl font-semibold text-blue-900">Name</h3>
            <p>Bitte gib deinen Namen an, mit dem du Erinnerungen in {userName.firstName}s Zeitleiste hinterlassen möchtest.</p>
            <input
              type="text"
              placeholder="Gib deinen Namen ein"
              className="w-full p-2 mb-4 border rounded"
              value={loginInput}
              onChange={(e) => setLoginInput(e.target.value)}
            />
            <button
              className="w-full px-4 py-2 text-white bg-blue-600 rounded"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </motion.div>
        </AnimatePresence>
      )}
<div className="fixed transform -translate-x-1/2 bottom-4 left-1/2">
  <button
    className="flex items-center justify-center w-16 h-16 text-3xl text-white bg-blue-600 rounded-full shadow-lg"
    onClick={() => {
      if (loggedInUser) {
        setShowCreateForm(true);
      } else {
        setShowLoginMessage(true);
        setTimeout(() => setShowLoginMessage(false), 3000); // Nachricht nach 3s ausblenden
      }
    }}
  >
    <Plus weight="bold" />
  </button>
</div>
{/* Login Message */}
{showLoginMessage && (
  <div className="fixed px-4 py-3 text-red-700 transform -translate-x-1/2 bg-red-100 border border-red-400 rounded bottom-24 left-1/2">
    Bitte melden Sie sich an, um einen neuen Eintrag zu erstellen.
  </div>
)}
      {/* Popover for Create Entry */}
      {showCreateForm && (
        <div className="fixed bottom-0 left-0 w-full p-6 overflow-y-auto bg-white shadow-lg rounded-t-xl top-20 items-left custom-scrollbar">
          {creatingEntry ? (
            <p className="text-center text-gray-500">Saving...</p>
          ) : (
            <>
              <h3 className="mb-4 text-xl font-semibold text-blue-900">Erinnerung hinzufügen</h3>
              <div className="flex flex-col mb-4 space-y-4 items-left justify-left">

                <div className="flex flex-wrap space-x-2">
                  {newEntryData.photos.map((photo, index) => (
                    <div key={index} className="relative w-24 h-24 border rounded">
                      <img
                        src={URL.createObjectURL(photo)}
                        alt={`Uploaded ${index + 1}`}
                        className="object-cover w-full h-full rounded"
                      />
                    </div>
                  ))}
                  <label className="flex items-center justify-center w-24 h-24 border rounded cursor-pointer">
                    <span className="text-2xl text-gray-500"><Camera /></span>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      className="hidden"
                      onChange={handlePhotoUpload}
                    />
                  </label>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <input
                  type="number"
                  placeholder="Jahr (Pflichtfeld)"
                  className="p-2 border rounded"
                  value={newEntryData.year}
                  onChange={(e) => setNewEntryData({ ...newEntryData, year: e.target.value })}
                />
<select
  className="p-2 border rounded"
  value={newEntryData.month}
  onChange={(e) => setNewEntryData({ ...newEntryData, month: e.target.value })}
>
  <option value="">Monat wählen (Pflichtfeld)</option>
  {months.map((month) => (
    <option key={month.value} value={month.value}>
      {month.name}
    </option>
  ))}
</select>
<select
  className="p-2 border rounded"
  value={newEntryData.day}
  onChange={(e) => setNewEntryData({ ...newEntryData, day: e.target.value })}
>
  <option value="">Tag auswählen</option>
  {days.map(day => (
    <option key={day} value={day}>
      {day}
    </option>
  ))}
</select>
  
                <input
                  type="text"
                  placeholder="Titel"
                  className="p-2 border rounded"
                  value={newEntryData.title}
                  onChange={(e) => setNewEntryData({ ...newEntryData, title: e.target.value })}
                />
                <textarea
                  placeholder="Beschreibung"
                  className="p-2 border rounded"
                  value={newEntryData.description}
                  onChange={(e) => setNewEntryData({ ...newEntryData, description: e.target.value })}
                ></textarea>
                <div className="flex flex-col space-y-4 items-left justify-left">
  {/*!isRecording ? (
    <button
      className="p-2 text-white bg-blue-500 rounded"
      onClick={startRecording}
    >
      Sprachaufnahme starten
    </button>
  ) : (
    <button
      className="p-2 text-white bg-red-500 rounded"
      onClick={stopRecording}
    >
      Sprachaufnahme stoppen
    </button>
  )*/}


{newEntryData.audioBlob && (
 
  <div className="flex flex-col gap-2 mt-2">
    <audio 
      src={URL.createObjectURL(newEntryData.audioBlob)} 
      controls 
      className="w-full max-w-xs"
    />
    <button
      onClick={() => {
        setNewEntryData({ ...newEntryData, audioBlob: null });
      }}
      className="inline-flex w-48 px-3 py-1 text-sm text-red-600 bg-red-100 rounded-md items-left hover:bg-red-200"
    >
      Sprachaufnahme löschen
    </button>
  </div>
)}
</div>

                <button
  type="button"
  className={`p-2 text-white rounded ${
    isSaving ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-500'
  }`}
  onClick={handleCreateEntry}
  disabled={isSaving} // Button während des Ladens deaktivieren
>
  {isSaving ? 'Erinnerung wird erstellt...' : 'Speichern'}
</button>

                <button
                  type="button"
                  className="p-2 text-white bg-red-500 rounded"
                  onClick={() => setShowCreateForm(false)}
                >
                  Abbrechen
                </button>
              </div>
            </>
          )}
        </div>
      )}
{editingEntry && (
  <div className="fixed bottom-0 left-0 w-full p-6 overflow-y-auto bg-white shadow-lg top-20 items-left custom-scrollbar">
    <h3 className="mb-4 text-xl font-semibold text-blue-900">Eintrag bearbeiten</h3>
    <div className="flex flex-col space-y-4">
      <input
        type="number"
        placeholder="Jahr"
        className="p-2 border rounded"
        value={editingEntry.year}
        onChange={(e) =>
          setEditingEntry((prev) => ({ ...prev, year: e.target.value }))
        }
      />
<select
  className="p-2 border rounded"
  value={String(editingEntry.month) || ''} 
  onChange={(e) =>
    setEditingEntry((prev) => ({ ...prev, month: e.target.value }))
  }
>
  <option value="">Monat wählen</option>
  {months.map((month) => (
    <option key={month.value} value={String(month.value)}>
      {month.name}
    </option>
  ))}
</select>
<select
  className="p-2 border rounded"
  value={editingEntry.day || ''} // Standardwert '' falls kein Tag ausgewählt
  onChange={(e) =>
    setEditingEntry((prev) => ({ ...prev, day: e.target.value }))
  }
>
  <option value="">Tag auswählen</option>
  {days.map(day => (
    <option key={day} value={day}>
      {day}
    </option>
  ))}
</select>
      <input
        type="text"
        placeholder="Title"
        className="p-2 border rounded"
        value={editingEntry.title}
        onChange={(e) =>
          setEditingEntry((prev) => ({ ...prev, title: e.target.value }))
        }
      />
      <textarea
        placeholder="Kommentar"
        className="p-2 border rounded"
        value={editingEntry.description}
        onChange={(e) =>
          setEditingEntry((prev) => ({ ...prev, description: e.target.value }))
        }
      ></textarea>
      <button
        className="p-2 text-white bg-green-500 rounded"
        onClick={() => handleSaveEntry(editingEntry)}
      >
        Änderungen speichern
      </button>
      <button
        className="p-2 text-white bg-red-500 rounded"
        onClick={() => setEditingEntry(null)}
      >
        Abbrechen
      </button>
    </div>
  </div>
)}
{entryDetails && (
  <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full overflow-hidden bg-black bg-opacity-50">
    <div className="popover-container rounded-t-2xl fixed bottom-0 left-0 w-full p-6 bg-white shadow-lg min-h-[350px]">
      <button
        className="absolute w-8 h-8 text-xl font-bold text-gray-500 top-3 right-3"
        onClick={closePopover}
      >
        <X size={24} weight="bold"/>
      </button>
      <div className="flex flex-col h-full ">
      <div className="flex flex-col items-start w-full h-full pt-8 overflow-y-auto custom-scrollbar">
        <div className="relative w-full overflow-hidden">
          {entryDetails?.images?.length > 0 ? (
            entryDetails.images.length > 1 ? (
              <div className="flex overflow-x-scroll w-full h-[370px] overflow-y-hidden custom-scrollbar">
                {entryDetails.images.map((image, index) => (
                  <img 
                    key={index} 
                    src={image?.url || '/placeholder.jpg'} 
                    alt={`entry image ${index}`} 
                    className="w-3270px] h-[370px] rounded-md object-cover mr-2 overflow-y-hide last:mr-0" 
                  />
                ))}
              </div>
            ) : (
                <div className="flex items-center justify-center overflow-x-scroll w-full h-[370px] overflow-y-hidden custom-scrollbar">

              <img 
                src={entryDetails.images[0]?.url || '/placeholder.jpg'} 
                alt="entry image" 
                className="w-full max-w-[370px] h-auto object-cover" 
              />
              </div>
            )
          ) : null}
        </div>
        <div className="mt-2 text-xs">Erinnerung hinzugefügt von {entryDetails.creator}</div>
        <div className="mt-2 text-2xl font-bold">{entryDetails.dateTitle}</div>
        <div className="mt-1 text-lg font-bold">{entryDetails.title}</div>
        <div className="mt-1 text-sm font-light">{entryDetails.comment}</div>
        {entryDetails?.audio && (
  <audio controls>
    <source src={entryDetails.audio} type="audio/webm" />
    Dein Browser unterstützt kein Audio-Element.
  </audio>
)}

      </div>
      {entryDetails.creator === loggedInUser && (
        <div className="flex flex-col items-center justify-between w-full gap-2">
  <button
    className="w-full p-2 text-white bg-blue-500 rounded"
    onClick={() => {
        console.log('Bearbeiten geklickt:', entryDetails);
        setEditingEntry({ ...entryDetails });
        console.log('Eingestellte Daten:', { ...entryDetails });
        setEntryDetails(null); // Schließt das Detail-Popup

      }}
  >
    Bearbeiten
  </button>
  <button
        className="w-full p-2 text-white bg-red-500 rounded"
        onClick={() => handleDeleteEntry(entryDetails.data?.entryId, entryDetails.phase)}
      >
        Löschen
      </button>
  </div>
)}
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default Timeline;