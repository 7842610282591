import React, { useState } from 'react';
import { getUsers } from '../api/ninox';

const Home = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSearch = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await getUsers(searchQuery);
            console.log('Users from API:', result); // Logge die Benutzerliste
            setUsers(result);
        } catch (err) {
            console.error('Fehler beim Abrufen der Benutzerdaten:', err.message);
            setError('Fehler beim Abrufen der Benutzerdaten.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="mb-6 text-4xl font-bold">Willkommen bei Uplivion</h1>
            <div className="w-full max-w-md">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Vor- oder Nachname eingeben"
                />
                <button
                    onClick={handleSearch}
                    className="w-full p-3 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                >
                    Benutzer suchen
                </button>
            </div>
            {loading && <p className="mt-4 text-gray-500">Lade Benutzer...</p>}
            {error && <p className="mt-4 text-red-500">{error}</p>}
            <div className="w-full max-w-3xl mt-6 space-y-4">
                {users.map((user) => (
                    <div
                        key={user.id}
                        className="p-4 bg-white border border-gray-300 rounded-lg shadow cursor-pointer hover:shadow-lg"
                        onClick={() => (window.location.href = `/timeline/${user.slug}`)}
                    >
                        <h2 className="text-xl font-bold">
                            {user.firstName} {user.lastName}
                        </h2>
                        <p className="text-gray-600">Slug: {user.slug}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;
