import React from 'react';

// Definiere deine SVGs als React-Komponenten
const SVGs = {
  sparkle: (
<svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
  <g id="color">
    <path fill="#FCEA2B" stroke="none" d="M25.7052,40.1067c0.1453,0.8051,0.9245,1.4619,1.9675,1.6589l11.9059,2.2495 c0.5576,0.1054,0.564,0.7031,0.0064,0.8085c-3.1597,0.597-9.0176,1.7037-11.9188,2.2519c-1.043,0.1971-1.8155,0.8529-1.9608,1.6579 l-3.1563,17.4924c-0.0868,0.4811-1.0073,0.4811-1.0942,0l-3.1563-17.4921c-0.1453-0.8051-0.9179-1.461-1.9611-1.6581 c-2.9014-0.5479-8.7594-1.6546-11.9193-2.2516c-0.5576-0.1053-0.5576-0.7019,0-0.8072c3.1601-0.597,9.0184-1.7039,11.9197-2.2523 c1.0429-0.1971,1.172-1.1419,1.3173-1.9469l2.6528-14.0921c0.0868-0.4811,2.1538-3.594,2.2406-3.1128 C23.5311,28.057,25.5109,39.0291,25.7052,40.1067z"/>
    <path fill="#F1B31C" stroke="none" d="M22.6499,66.2258l1.5818-15.4034C21.4322,46.2039,20.3623,41.4122,19.0589,38l-1.3554,2.1201 c-0.1349,0.8046-0.8519,1.46-1.8203,1.6571c-2.6937,0.548-8.133,1.6544-11.0668,2.2512c-0.5177,0.1053-0.5177,0.7015,0,0.8068 c2.9338,0.5967,8.3727,1.7028,11.0664,2.2504c0.9685,0.197,2.6859,0.8525,2.8208,1.6572l2.9305,17.4831 C21.7147,66.7068,22.5694,66.7068,22.6499,66.2258z"/>
    <path fill="#FCEA2B" stroke="none" d="M46.676,19.3048c0.1049,0.5815,0.6677,1.0558,1.421,1.1981l8.5987,1.6246 c0.4027,0.0761,0.4073,0.5078,0.0046,0.5839c-2.282,0.4312-6.5127,1.2304-8.6081,1.6263c-0.7533,0.1423-1.3112,0.616-1.4161,1.1974 l-2.2795,12.6334c-0.0627,0.3475-0.7275,0.3475-0.7902,0l-2.2795-12.6332c-0.1049-0.5815-0.6629-1.0552-1.4163-1.1975 c-2.0955-0.3957-6.3263-1.195-8.6084-1.6262c-0.4027-0.0761-0.4027-0.5069,0-0.583c2.2823-0.4312,6.5133-1.2306,8.6086-1.6267 c0.7532-0.1423,0.8465-0.8247,0.9514-1.4061l1.7931-10.1776c0.0627-0.3475,1.6784-2.5957,1.741-2.2481 C45.1058,10.6022,46.5356,18.5265,46.676,19.3048z"/>
    <path fill="#F1B31C" stroke="none" d="M44.7472,38.1686l0.8646-11.1247c-2.0219-3.3355-3.5168-6.7963-4.4581-9.2606l-0.2567,1.5312 c-0.0974,0.5811-0.6153,1.0544-1.3147,1.1968c-1.9454,0.3958-5.8738,1.1948-7.9927,1.6259c-0.3739,0.076-0.3739,0.5066,0,0.5827 c2.1189,0.431,6.0469,1.2298,7.9924,1.6253c0.6995,0.1423,2.2176,0.6157,2.315,1.1969l2.1164,12.6266 C44.0717,38.516,44.689,38.516,44.7472,38.1686z"/>
    <path fill="#FCEA2B" stroke="none" d="M56.8824,48.9358c0.0862,0.4637,0.5487,0.8419,1.1677,0.9554l7.0663,1.2955 c0.3309,0.0607,0.3347,0.4049,0.0038,0.4656c-1.8753,0.3438-5.352,0.9812-7.074,1.2969c-0.619,0.1135-1.0775,0.4912-1.1637,0.9548 l-1.8733,10.0742c-0.0515,0.2771-0.5978,0.2771-0.6494,0l-1.8733-10.074c-0.0862-0.4637-0.5448-0.8414-1.1639-0.9549 c-1.722-0.3156-5.1988-0.9529-7.0743-1.2967c-0.3309-0.0607-0.3309-0.4042,0-0.4649c1.8755-0.3438,5.3525-0.9813,7.0745-1.2972 c0.619-0.1135,0.6956-0.6577,0.7818-1.1213l1.4736-8.1159c0.0515-0.2771,1.3793-2.0699,1.4308-1.7927 C55.592,41.9962,56.767,48.3151,56.8824,48.9358z"/>
    <path fill="#F1B31C" stroke="none" d="M55.2315,63.9782l1.0538-8.8711c-1.6123-2.6598-2.8044-5.4195-3.555-7.3846l-0.2047,1.221 c-0.0777,0.4634-0.4906,0.8408-1.0484,0.9543c-1.5513,0.3156-4.6839,0.9528-6.3736,1.2965c-0.2982,0.0606-0.2982,0.404,0,0.4646 c1.6896,0.3437,4.822,0.9807,6.3733,1.2961c0.5578,0.1134,0.9709,0.491,1.0486,0.9544l2.1208,10.0688 C54.6929,64.2552,55.1851,64.2552,55.2315,63.9782z"/>
  </g>
  <g id="hair"/>
  <g id="skin"/>
  <g id="skin-shadow"/>
  <g id="line">
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M20,32l2.1418-11.1605l0.6996,4.1763c0.1122,0.6679,2.3298,13.9055,2.3298,13.9055c0.2615,1.5604,1.6435,2.8154,3.5205,3.1974 L40,44.4198l-11.3161,2.3026c-1.8724,0.381-3.2513,1.6356-3.5127,3.1963L22.1422,68l-3.029-18.0806 c-0.2615-1.5607-1.6405-2.8153-3.5131-3.1964L4.2836,44.4205l11.3171-2.3031C17.4727,41.7362,18.7387,39.5604,19,38"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M42.5556,13.4499l1.5469-8.0604l0.5052,3.0162c0.081,0.4824,1.6827,10.0429,1.6827,10.0429 c0.1889,1.1269,1.1869,2.0334,2.5426,2.3092L57,22.4198l-8.1728,1.663c-1.3523,0.2752-2.3482,1.1812-2.5369,2.3084l-2.1876,13.0587 l-2.1876-13.0582c-0.1889-1.1272-1.1848-2.0333-2.5372-2.3085l-8.173-1.6629l8.1735-1.6634 c1.352-0.2753,2.2663-1.8467,2.455-2.9736"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M53.8482,44.267l1.2335-6.4275l0.4029,2.4052c0.0646,0.3847,1.3418,8.0084,1.3418,8.0084 c0.1506,0.8986,0.9465,1.6215,2.0275,1.8414l6.5126,1.3253l-6.5171,1.3261c-1.0783,0.2194-1.8725,0.9419-2.023,1.8408L55.0819,65 l-1.7445-10.4129c-0.1506-0.8988-0.9448-1.6214-2.0232-1.8409l-6.5174-1.326l6.5177-1.3264 c1.0781-0.2195,1.8072-1.4726,1.9577-2.3712"/>
  </g>
</svg>
  ),
  plant: (
    <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
    <g id="color">
      <rect x="38.9184" y="33.8117" rx="0.8031" ry="0.8031" width="3.3818" height="4.4162" fill="#fff"/>
      <rect x="28.6385" y="33.6681" rx="0.8031" ry="0.8031" width="3.3818" height="4.4162" fill="#fff"/>
      <path fill="#f4aa41" d="m42.2688,60.9583h-13.5936c-.4066-.0002-.7532-.2951-.8187-.6964l-2.4489-15.05c-.0735-.452.2334-.8779.6853-.9514.0002,0,.0005,0,.0007-.0001.4526-.0737,18.3067-.0745,18.7569,0,.452.0732.7592.4989.686.9509,0,.0002,0,.0004,0,.0006l-2.4488,15.05c-.0655.4014-.4121.6962-.8188.6964Z"/>
      <path fill="#ea5a47" d="m47.7634,45.9079h-24.5828c-.4577.0002-.829-.3707-.8292-.8284v-5.1481c-.0002-.4577.3707-.829.8284-.8292h24.5836c.4577-.0003.829.3706.8293.8283v5.1481c.0003.4577-.3706.829-.8283.8293h-.001Z"/>
      <path fill="#5c9e31" d="m35.2717,32.3523c-.4262-.0004-.8054-.2709-.9444-.6738-1.2588-3.6514-13.1757-11.21-17.6611-13.8144-.4777-.2772-.6402-.8891-.3631-1.3668.0866-.1492.2103-.2735.3592-.3608,3.188-1.8011,7.0204-2.0604,10.4219-.7051l.0175.0088c8.8291,4.1338,9.1612,15.4131,9.1709,15.8916.0101.4922-.3396.9185-.8242,1.0049-.0583.0105-.1174.0157-.1767.0156Z"/>
      <path fill="#b1cc33" d="m50.0949,12.8416c.4594-.308.5821-.93.2742-1.3894-.0835-.1245-.1939-.2287-.323-.3049-4.1499-2.2053-9.2797-1.1726-12.2529,2.4668-4.8512,5.46-3.7389,14.053-3.4178,15.9636-2.3254-1.7177-7.0533-4.3196-12.3954-2.1746-.0127.0049-.0254.01-.0371.0156-.1719.0762-4.2041,1.9356-4.2608,6.3428-.0073.5519.4341,1.0053.986,1.0126.0047,0,.0093,0,.014,0,.0891,0,.1777-.0119.2637-.0351.1082-.0293,10.8372-2.9316,16.073-2.1958l.0163.0019.0123.0015c.0587.0045.1177.0038.1762-.0023.0408.0024.0818.0022.1226-.0007l.01-.0012.01-.0011c4.8706-.7582,13.4261,1.5561,13.5129,1.5795.086.0232.1746.035.2637.0351.5519.0003.9995-.4469.9998-.9988,0-.0766-.0087-.153-.0262-.2277-.8019-2.9072-3.2561-5.0589-6.2432-5.4736h-.0009c-2.6292-.2871-5.2731.3906-7.44,1.9071.174-7.4212,13.5253-16.4302,13.6626-16.5214Z"/>
      <path fill="#5c9e31" d="m35.1935,31.323c-.5523-.0004-.9996-.4485-.9992-1.0008,0-.109.018-.2172.053-.3204.123-.3614,3.082-8.8575,10.2128-11.3184h0c7.4122-2.5606,11.0938,3.1465,11.1309,3.2041.2932.4684.1512,1.0858-.3172,1.3791-.1326.083-.2829.1338-.4388.1482-4.3261.4-16.1289,2.5069-18.7607,7.3828-.1746.3239-.513.5257-.8809.5254Zm9.5928-11.6943h0Z"/>
      <path fill="#a57939" d="m31.19,39.1022v-1.32c.0045-1.1162.4746-2.1798,1.2969-2.9345-.1952-.4024-.2968-.8438-.2969-1.2911,0-1.6569,1.3431-3,3-3s3,1.3431,3,3c-.0001.4473-.1016.8887-.2968,1.2911.8224.7546,1.2925,1.8182,1.2968,2.9343v1.32l-8,.0002Z"/>
    </g>
    <g id="line">
      <rect x="23.1806" y="39.9315" width="24.583" height="5.147" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="26.678 47.853 28.675 60.129 42.269 60.129 44.266 47.853"/>
      <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m38.1376,37.2208c-.1831-.9379-.8014-1.7327-1.6655-2.1408.848-.7078.9617-1.969.2539-2.817-.7078-.848-1.969-.9617-2.817-.2539-.848.7078-.9617,1.969-.2539,2.817.0769.0921.1618.1771.2539.2539-.8673.4096-1.4869,1.2088-1.6674,2.1509"/>
      <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m41.8864,31.7085c2.453.3379,4.8835.8226,7.2784,1.4515-.7188-2.5052-2.8456-4.3522-5.4268-4.7128-4.4986-.7141-8.5255,3.1133-8.5255,3.1133"/>
      <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m35.2585,31.3474s-.2277-11.089-8.5947-15.0072c-3.108-1.2167-6.5975-.977-9.51.653,4.0046,2.3534,7.8824,4.9162,11.6173,7.6777"/>
      <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m35.1953,31.5564s-5.9594-5.9847-12.8388-3.2258c0,0-3.6211,1.6242-3.67,5.4432,3.5205-.9345,7.0981-1.639,10.71-2.1093"/>
      <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m35.2122,30.3226c3.4659-6.4211,19.5492-7.9037,19.5492-7.9037,0,0-3.2069-5.12-9.9567-2.7906"/>
      <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m35.4193,29.7723c-.304-8.1412,14.1129-17.7623,14.1129-17.7623-3.7338-1.9917-8.357-1.0382-10.9982,2.2682-1.2157,1.4059-2.1149,3.0568-2.6366,4.8407"/>
    </g>
  </svg>
  ),
  glowingStar: (
  <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
  <g id="color">
    <polygon fill="#FCEA2B" stroke="none" points="35.9704,6.0368 27.7688,22.6704 9.4169,25.3441 22.702,38.2843 19.5737,56.5642 35.9859,47.928 52.4045,56.552 49.2626,38.2744 62.5382,25.3244 44.1842,22.6643"/>
    <polygon fill="#FCEA2B" stroke="none" points="14.1635,41.4594 4.8592,42.0365 6.4763,46.7329"/>
    <polygon fill="#FCEA2B" stroke="none" points="57.8365,40.9794 67.1408,41.5565 65.5237,46.2529"/>
    <polygon fill="#FCEA2B" stroke="none" points="22.0721,15.5917 18.648,6.9211 14.6811,9.9103"/>
    <polygon fill="#FCEA2B" stroke="none" points="50.4002,16.0862 57.9849,10.6662 54.1248,7.5403"/>
    <polygon fill="#FCEA2B" stroke="none" points="35.9197,56.9779 33.4362,65.9632 38.4032,65.9632"/>
  </g>
  <g id="hair"/>
  <g id="skin"/>
  <g id="skin-shadow"/>
  <g id="line">
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="35.9248,6.0368 27.7233,22.6704 9.3713,25.3441 22.6564,38.2843 19.5282,56.5642 35.9403,47.928 52.3589,56.552 49.2171,38.2744 62.4926,25.3244 44.1387,22.6643"/>
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="35.932,56.9779 33.4484,65.9632 38.4155,65.9632"/>
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="57.8156,41.0785 65.5937,46.2171 67.1286,41.4932"/>
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="14.1758,41.4594 4.8714,42.0365 6.4885,46.7329"/>
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="50.4125,16.0862 57.9972,10.6662 54.1371,7.5403"/>
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="22.0843,15.5917 18.6602,6.9211 14.6934,9.9103"/>
  </g>
</svg> ),

signpost: (
  <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
  <g id="color">
    <rect x="31.0713" y="10.4815" width="7.9472" height="55.1819" fill="#6a462f"/>
    <polygon fill="#a57939" points="31.071 18.66 31.071 10.481 39.018 10.481 39.018 16.558 31.071 18.66"/>
    <polygon fill="#a57939" points="42.605 14.992 58.181 14.992 62.873 21.172 58.181 27.421 42.605 27.421 42.605 14.992"/>
    <polygon fill="#6a462f" points="27.425 27.091 11.848 27.091 7.157 33.271 11.848 39.521 27.425 39.521 27.425 27.091"/>
    <polygon fill="#6a462f" points="42.605 23.216 60.975 18.672 62.873 21.172 58.181 27.421 42.605 27.421 42.605 23.216"/>
    <polygon fill="#a57939" points="7.157 33.271 27.425 27.091 11.848 27.091 7.157 33.271"/>
  </g>
  <g id="line">
    <polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="18.23 27.091 11.848 27.091 7.157 33.271 11.848 39.521 27.425 39.521 27.425 33.982"/>
    <polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="46.711 14.992 58.181 14.992 62.873 21.172 58.181 27.421 42.605 27.421 42.605 23.216"/>
    <polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="31.071 65.663 31.071 10.481 39.018 10.481 39.018 65.663"/>
  </g>
</svg>
),
tree: (
  <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
  <g id="color">
    <path fill="#b1cc33" d="m51.5605,17.7304c-.5093.0029-1.018.0333-1.524.091l-.673.072-.317-.6c-2.673-5.047-8.5-8.308-14.854-8.308-9.026,0-16.369,6.4-16.369,14.268.0014.5162.0348,1.0319.1,1.544l.1.824-.792.251c-4.015,1.272-6.608,4.186-6.608,7.421,0,4.445,4.851,8.061,10.813,8.061,2.3045.0227,4.5747-.5593,6.584-1.688l.591-.341.189.151.344.275c2.714,2.167,7.082,3.462,11.683,3.462,7.762,0,14.32-3.691,14.32-8.061-.0013-.2702-.0274-.5396-.078-.805l-.138-.751-.022-.117.842-.266c4.022-1.271,6.622-4.185,6.622-7.425.001-4.439-4.851-8.058-10.813-8.058Z"/>
    <path fill="#5c9e31" d="m39.4685,27.9974s4.917,7.331,15.443,5.488l.6272,1.7053-.8266,2.6189c-2.5901,3.1871-5.0927,4.6512-9.1032,5.5357l-7.9389.0438-7.2116-1.9327-1.846-2.126c5.1993-1.8606,9.2206-6.0585,10.856-11.333Z"/>
  </g>
  <g id="line">
    <polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="35.7895 63.9434 35.7895 52.7764 28.1585 45.2604"/>
    <line x1="35.7895" x2="40.1575" y1="52.7764" y2="48.2304" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="m63.3745,25.7734c0-5-5.289-9.061-11.813-9.061-.5451.0044-1.0895.0378-1.631.1-2.763-5.216-8.762-8.839-15.738-8.839-9.593,0-17.369,6.836-17.369,15.268.0017.5574.0378,1.1141.108,1.667-4.288,1.359-7.306,4.595-7.306,8.374,0,5,5.289,9.061,11.813,9.061,2.4798.021,4.922-.6072,7.084-1.822,2.791,2.229,7.257,3.681,12.307,3.681,8.461,0,15.32-4.057,15.32-9.061-.0014-.3308-.0329-.6609-.094-.986,4.293-1.36,7.319-4.598,7.319-8.382Z"/>
  </g>
</svg>
),
balance: (
  <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
  <g id="color">
    <path fill="#9B9B9A" stroke="none" d="M48.0626,62.92c0-3.3137-5.5964-6-12.5-6s-12.5,2.6863-12.5,6H48.0626z"/>
    <path fill="#D0CFCE" stroke="none" d="M24.9006,46.9656c0,2.1938-2.1985,3.9723-4.9106,3.9723s-4.9106-1.7785-4.9106-3.9723H24.9006z"/>
    <path fill="#D0CFCE" stroke="none" d="M57.9006,46.9248c0,2.1938-2.1985,3.9723-4.9106,3.9723c-2.712,0-4.9106-1.7785-4.9106-3.9723H57.9006z"/>
  </g>
  <g id="hair"/>
  <g id="skin"/>
  <g id="skin-shadow"/>
  <g id="line">
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M15.9996,25.1697c2.6667,0.0001,8.9544-5.3333,20.0002-5.3333S50.6666,23.8365,56,25.1697"/>
    <line x1="35.9998" x2="35.9998" y1="23.9064" y2="51.9064" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    <circle cx="35.9998" cy="13.895" r="3" fill="#000000" stroke="none"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M48,61.9064c0-3.3137-5.5964-6-12.5-6s-12.5,2.6863-12.5,6"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M26,45.9064c0,3.3137-2.6863,6-6,6s-6-2.6863-6-6H26z"/>
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="20,27.9064 15,45.9064 25,45.9064"/>
    <line x1="20" x2="20" y1="27.9064" y2="45.9064" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M59,45.9064c0,3.3137-2.6863,6-6,6s-6-2.6863-6-6H59z"/>
    <polygon fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="53,27.9064 48,45.9064 58,45.9064"/>
    <line x1="53" x2="53" y1="27.9064" y2="45.9064" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
  </g>
</svg>
),
butterfly: (

  <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
  <g id="color">
    <path fill="#92D3F5" stroke="none" d="m35.892 22.845c-1.1677-0.75117-2.3515-1.5127-4.2562-2.536-1.9047-1.0233-4.5628-2.3295-7.3719-3.0366-2.8091-0.70712-5.7684-0.81539-8.9995-0.92767-3.2311-0.11228-6.7338-0.22857-8.7991 0.71011s-2.694 2.9321-0.63572 6.7601 6.8063 9.4959 11.128 11.07 8.2242-0.93837 11.039-2.5076c2.8152-1.5692 4.5425-2.1943 6.2711-2.8198"/>
    <path fill="#92D3F5" stroke="#92D3F5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m32.103 44.066c-0.75813 3.7545-1.516 7.5079-3.6638 9.4562-2.1478 1.9483-5.6846 2.0927-7.9036-0.93857-2.219-3.0312-3.1212-9.2385-2.4695-12.725 0.65167-3.4868 2.8607-4.2531 5.602-5.4795 2.7413-1.2264 6.0204-2.9151 9.3011-4.6046z"/>
    <path fill="#92D3F5" stroke="none" d="m36.108 22.845c1.1677-0.75117 2.3515-1.5127 4.2562-2.536 1.9047-1.0233 4.5628-2.3295 7.3719-3.0366 2.8091-0.70712 5.7684-0.81539 8.9995-0.92767 3.2311-0.11228 6.7338-0.22857 8.7992 0.71041 2.0654 0.93898 2.6939 2.9319 0.63551 6.76-2.0584 3.8281-6.8031 9.492-11.126 11.068-4.3233 1.576-8.226-0.93668-11.041-2.5058-2.815-1.5691-4.5423-2.1941-6.2708-2.8196"/>
    <path fill="#92D3F5" stroke="#92D3F5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m39.897 44.066c0.75813 3.7545 1.516 7.5079 3.6638 9.4562 2.1478 1.9483 5.6846 2.0927 7.9036-0.93857 2.219-3.0312 3.1212-9.2385 2.4697-12.725-0.65148-3.4867-2.8579-4.2521-5.6008-5.4791-2.7429-1.227-6.022-2.9157-9.3024-4.605 z"/>
    <ellipse cx="35.807" cy="17.922" rx="3.5601" ry="2.6701" fill="#9B9B9A" stroke="none"/>
    <ellipse cx="35.819" cy="23.563" rx="3.353" ry="2.7346" fill="#3F3F3F" stroke="none"/>
    <path fill="#9B9B9A" stroke="none" d="m35.394 26.812c-6.3212 0.67634 0.6061 20.481 0.6061 20.481s7.8636-21.387-0.6061-20.481z"/>
  </g>
  <g id="hair"/>
  <g id="skin"/>
  <g id="skin-shadow"/>
  <g id="line">
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m32.103 44.066c-0.75813 3.7545-1.516 7.5079-3.6638 9.4562-2.1478 1.9483-5.6846 2.0927-7.9036-0.93857-2.219-3.0312-3.1212-9.2385-2.4695-12.725 0.65167-3.4868 2.8581-4.2522 5.0655-5.0179"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m32.39 20.592c-2.6586-1.3063-5.3167-2.6125-8.1258-3.3196-2.8091-0.70712-5.7684-0.81539-8.9995-0.92767-3.2311-0.11228-6.7338-0.22857-8.7991 0.71011s-2.694 2.9321-0.63572 6.7601 6.8063 9.4959 11.128 11.07 8.2242-0.93837 12.129-3.4522"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m39.61 20.592c2.6586-1.3063 5.3167-2.6125 8.1258-3.3196 2.8091-0.70712 5.7684-0.81539 8.9995-0.92767 3.2311-0.11228 6.7338-0.22857 8.7992 0.71041 2.0654 0.93898 2.6939 2.9319 0.63551 6.76-2.0584 3.8281-6.8031 9.492-11.126 11.068-4.3233 1.576-8.226-0.93668-12.13-3.4502"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m39.897 44.066c0.75813 3.7545 1.516 7.5079 3.6638 9.4562 2.1478 1.9483 5.6846 2.0927 7.9036-0.93857 2.219-3.0312 3.1212-9.2385 2.4697-12.725-0.65148-3.4867-2.858-4.2521-5.0657-5.018"/>
    <ellipse cx="36" cy="17.922" rx="3.5601" ry="2.6701" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    <ellipse cx="36" cy="23.563" rx="3.353" ry="2.7346" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m35.392 26.812c-6.3212 0.67634 0.60836 20.481 0.60836 20.481s7.8614-21.387-0.60836-20.481z"/>
    <line x1="37.968" x2="39.452" y1="14.845" y2="11.582" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    <line x1="34.032" x2="32.548" y1="14.845" y2="11.582" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
  </g>
</svg>
),
sunrise: (
  <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
  <g id="color">
    <rect x="4.0003" y="47.2922" width="64" height="20.7868" fill="#61b2e4"/>
    <rect x="4.0003" y="4.079" width="64" height="43.2132" fill="#e27022"/>
    <polyline fill="#f4aa41" points="68 4.079 60 4.079 44 35.079 48 38.079 65 18.079 68 14.848"/>
    <polygon fill="#f4aa41" points="4 17.641 6.019 19.554 24.55 38.145 28.3 34.837 9 4.079 4 4.079 4 17.641"/>
    <polyline fill="#f4aa41" points="30.879 4.079 33.348 32.454 38.331 32.866 42.08 6.886 42.566 4.079"/>
    <polyline fill="#f4aa41" points="68 34.719 46.068 41.718 46.775 46.668 68 44.947"/>
    <polyline fill="#f4aa41" points="4 34.899 25.933 41.898 25.226 46.848 4 45.127"/>
    <path fill="#fcea2b" d="M21.48,47.2923a15.6793,15.6793,0,1,1,29.04.0006"/>
    <polygon fill="#fff" points="67.964 24.049 68 47.292 49.932 47.292 55.019 37.008 58.641 33.433 59.643 27.783 60.927 24.904 67.964 24.049"/>
    <polygon fill="#fff" points="4.037 24.049 4 47.292 21.48 47.292 18.327 36.079 14.848 32.091 14.967 28.846 11.073 24.904 4.037 24.049"/>
  </g>
  <g id="line">
    <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M8.48,32.188a7.7528,7.7528,0,0,1,9.8478,4.82"/>
    <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M5,24.0262a7.7528,7.7528,0,0,1,9.8478,4.82"/>
    <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M54.4754,40.5834a7.7527,7.7527,0,0,1,8.3317-7.127"/>
    <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M58.6412,30.7561a7.7529,7.7529,0,0,1,8.3317-7.1271"/>
    <line x1="5.1239" x2="66.8767" y1="47.2929" y2="47.2929" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
    <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M20.49,43.9627a15.6793,15.6793,0,1,1,30.7746.2534"/>
  </g>
</svg>
)
  // Weitere SVGs hier hinzufügen
};

const SVGIcon = ({ name, color = 'black', size = 24 }) => {
  const SVGComponent = SVGs[name];

  if (!SVGComponent) {
    return null; // Oder ein Platzhalter-SVG anzeigen
  }

  return (
    <div style={{ color, width: size, height: size }}>
      {SVGComponent}
    </div>
  );
};

export default SVGIcon;