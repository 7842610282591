import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://jynk35zav6.execute-api.eu-central-1.amazonaws.com/prod' // Dein API-Gateway-Endpoint in der Produktion
      : '/api', // Für die lokale Entwicklung mit Proxy
  headers: {
    'Content-Type': 'application/json',
  },
});
api.interceptors.request.use((config) => {
    console.log('Axios Request:', config);
    return config;
  });
// Presigned URL abrufen
export const getPresignedUrl = async (fileName, contentType) => {
    try {
      const response = await api.get(`/presigned-url?key=${fileName}&contentType=${contentType}`);
      return response.data.url; // Rückgabe der Presigned URL
    } catch (error) {
      console.error(
        'Fehler beim Abrufen der Presigned URL:',
        error.response?.data || error.message
      );
      throw error;
    }
  };
  
  // Datei direkt zu S3 hochladen
  export const uploadFileToS3 = async (url, file) => {
    try {
      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      console.log('Datei erfolgreich hochgeladen:', file.name);
    } catch (error) {
      console.error(
        'Fehler beim Hochladen der Datei zu S3:',
        error.response?.data || error.message
      );
      throw error;
    }
  };
// Lifeline-Daten abrufen
export const getUserLifeline = async (userId) => {
  const requestBody = {
    action: 'getUserLifeline',
    payload: { userId },
  };

  try {
    const response = await api.post('/', requestBody);
    console.log('Lifeline Response:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      'Fehler beim Abrufen der Lifeline:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Phase-Einträge abrufen
export const getPhaseEntries = async (phaseId) => {
  const requestBody = {
    action: 'getPhaseEntries',
    payload: { phaseId },
  };

  try {
    console.log('Phase Entries Payload:', requestBody);
    const response = await api.post('/', requestBody);
    console.log('Phase Entries Response:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      'Fehler beim Abrufen der Phase-Einträge:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Neuen Phase-Eintrag erstellen
export const createPhaseEntry = async (entryData) => {
  const requestBody = {
    action: 'createPhaseEntry',
    payload: entryData,
  };

  try {
    const response = await api.post('/', requestBody);
    console.log('Create Entry Response:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      'Fehler beim Erstellen des Eintrags:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Phase-ID anhand der Entry-ID abrufen
export const getPhaseIdByEntryId = async (entryId) => {
  const requestBody = {
    action: 'getPhaseIdByEntryId',
    payload: { entryId },
  };

  try {
    const response = await api.post('/', requestBody);
    console.log('Phase ID für Entry ID erhalten:', response.data);
    return response.data.result.phaseId;
  } catch (error) {
    console.error(
      'Fehler beim Abrufen der Phase-ID:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Entry-Details anhand der Entry-ID abrufen
export const getEntryDetails = async (entryId) => {
  const requestBody = {
    action: 'getEntryDetails',
    payload: { entryId },
  };

  try {
    const response = await api.post('/', requestBody);
    console.log('Eintragsdetails gesendet:', requestBody);
    console.log('Eintragsdetails erhalten:', response.data);
    return response.data.result; // Rückgabe der Details des Eintrags
  } catch (error) {
    console.error(
      'Fehler beim Abrufen der Eintragsdetails:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Benutzerliste abrufen
export const getUsers = async (searchQuery) => {
  const requestBody = {
    action: 'getUsers',
    payload: { query: searchQuery },
  };
  
  try {
    console.log(process.env.NODE_ENV);
    console.log('Request URL:', api.defaults.baseURL);
    const response = await api.post('/', requestBody);
    console.log('Users Response:', response);
    return response.data.result.users;
  } catch (error) {
    console.error(
      'Fehler beim Abrufen der Benutzer:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Funktion zum Aktualisieren eines Eintrags
export const updatePhaseEntry = async (updatedEntry) => {
    const requestBody = {
      action: 'updatePhaseEntry', // Der Aktionsname, den dein Backend erwartet
      payload: updatedEntry, // Die aktualisierten Daten des Eintrags
    };
  
    try {
      const response = await api.post('/', requestBody);
      console.log('Update Entry Response:', response.data);
      return response.data; // Rückgabe der Antwort des Backends
    } catch (error) {
      console.error(
        'Fehler beim Aktualisieren des Eintrags:',
        error.response?.data || error.message
      );
      throw error;
    }
  };

// Funktion zum Löschen eines Eintrags
export const deletePhaseEntry = async (deletePhaseEntry) => {
    
    const requestBody = {
      action: 'deletePhaseEntry', 
      payload: deletePhaseEntry,
    };
  
    try {
        console.log('Delete Entry Payload:',requestBody);
      const response = await api.post('/', requestBody);
      console.log('Delete Entry Response:', response.data);
      return response.data;
    } catch (error) {
      console.error(
        'Fehler beim Löschen des Eintrags:',
        error.response?.data || error.message
      );
      throw error;
    }
  };