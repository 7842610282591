import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Timeline from './pages/Timeline';
import './App.css';

function App() {
    return (
        <Router>
            <div className="app">
                <Routes>
                    {/* Route für die Startseite */}
                    <Route path="/" element={<Home />} />

                    {/* Route für die Timeline mit dynamischem Slug */}
                    <Route path="/timeline/:slug" element={<Timeline />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
